/* Counter */
.counter {
    margin: 20px;
    position: relative;
}

.counter .flex-counter {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 30px 5px;
    background: linear-gradient(135deg, #236092 0.88%, #270089 96.87%);
    box-shadow: 0px 10px 20px 0px #00000026;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
}

.counter .flex-counter:after {
    content: "";
    position: absolute;
    background-image: url(../../images/counter-bg.png);
    background-size: contain;
    background-position: right bottom;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    opacity: 0.4;
    background-color: #236092;
    background-blend-mode: overlay;
    z-index: 0;
    right: 0;
}

.counter .flex-counter .countBox {
    min-width: 176px;
    width: auto;
    height: 77.6px;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 0 15px;
    position: relative;
    z-index: 1;
}

.counter .flex-counter .countBox h6 {
    font-family: 'Manrope', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #696969;
    margin: 0 0 12px;
}

.counter .flex-counter .countBox h1 {
    font-family: 'Manrope', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #270089;
    margin: 0;
}
.counter .flex-counter .countBox h1 span.currency {
    color: #236092;
    font-size: 13px;
    position: relative;
    top: -13px;
    margin-left: 5px;
}
