.navSlide {
  width: 100px;
  height: 100vh;
  border-right: 1px solid #e6e6e6;
  transition: 0.3s ease-in-out;
}
/* .navSlide.full {
    width: 363px;
    height: 100vh;
    border-right: 1px solid #E6E6E6;
} */

.navSlide .navBox {
  width: 100%;
}

/* .navSlide.full .navBox .navSlideHeader{
    padding-right: 40px;} */

.navSlide .navBox .navSlideHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-right: 15px;
  margin-bottom: 20px;
}

.navSlide .navBox .navSlideHeader .logo {
}

.navSlide .navBox .navSlideHeader .logo a.showLogo {
  display: flex;
  align-items: center;
  justify-content: start;
}

.navSlide.full .navBox .navSlideHeader .logo a.showLogo .logoIcon {
}
.navSlide .navBox .navSlideHeader .logo a.showLogo .logoIcon {
  margin-right: 15px;
}

.navSlide.full .navBox .navSlideHeader .logo a.showLogo .logoIcon svg {
  width: auto;
  height: auto;
}
.navSlide .navBox .navSlideHeader .logo a.showLogo .logoIcon svg {
  width: 25px;
  height: 25px;
}

.navSlide.full .navBox .navSlideHeader .logo a.showLogo .logoText {
  display: block;
}
.navSlide .navBox .navSlideHeader .logo a.showLogo .logoText {
  display: none;
}

.navSlide .navBox .navSlideHeader .navToggle {
}

.navSlide .navBox .navSlideHeader .navToggle .showNav {
  visibility: hidden;
  height: 0;
  cursor: pointer;
}
.navSlide.full .navBox .navSlideHeader .navToggle .showNav {
  visibility: visible;
  height: auto;
}

.navSlide .navBox .navSlideHeader .navToggle .closeNav {
  visibility: visible;
  height: auto;
  cursor: pointer;
}
.navSlide.full .navBox .navSlideHeader .navToggle .closeNav {
  visibility: hidden;
  height: 0;
}

.navSlide .navBox .navSlideBody {
  padding-right: 0;
}

.navSlide .navBox .navSlideBody .mainCat.accordion {
  border-radius: 0;
}

.navSlide .navBox .navSlideBody .mainCat.accordion .accordion-item {
  border-radius: 0;
  border: 0;
  margin-bottom: 15px;
  padding-right: 0px;
}

.navSlide.full
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header {
  padding-right: 40px;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header {
  border-radius: 0;
  box-shadow: 0 0 0 0;
  padding-right: 15px;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button {
  /* background: linear-gradient(135deg, #236092 0.88%, #270089 96.87%); */
  background: linear-gradient(137deg, #1a267b 42.88%, #002bcf 96.87%);
  height: 50px;
  border-radius: 0 30px 30px 0px;
  box-shadow: 0 0 0 0;
  position: relative;
}

/* .navSlide .navBox .navSlideBody .mainCat.accordion .accordion-item h2.accordion-header button.accordion-button:is([aria-expanded="true"], .collapsed[aria-expanded="false"]) {
    background: linear-gradient(135deg, #236092 0.88%, #270089 96.87%);height: 50px;border-radius: 0 30px 30px 0px;
    box-shadow: 0 0 0 0; position: relative;
} */

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button:before {
  content: "";
  background-image: url(http://localhost:3000/static/media/counter-bg.5ca84006f0421c540dad.png);
  position: absolute;
  width: 170px;
  height: 50px;
  right: -10px;
  background-size: 100%;
  opacity: 0.3;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button
  a {
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0 20px;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  .icon {
  margin-right: 10px;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed
  .icon {
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed
  .icon
  svg {
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed
  .icon
  svg
  path {
  fill: #270089;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button
  .icon
  svg
  path {
  fill: #fae345;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  .navTitle {
  color: #fae345;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  display: none;
}
.navSlide.full
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  .navTitle {
  display: block;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  .accordion-collapse {
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  .accordion-collapse
  .accordion-body {
  display: none;
}
.navSlide.full
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  .accordion-collapse
  .accordion-body {
  display: block;
}

.navSlide .navBox .navSlideBody .mainCat.accordion .subCat.accordion {
}

.accordion-item {
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item {
  margin: 10px 0;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header {
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed {
  background: transparent;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button {
  background: white;
  padding: 0;
  padding-left: 80px;
  height: 30px;
  background: #2700890d;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  .accordion-collapse.collapse {
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  .accordion-collapse
  .accordion-body {
  padding: 0;
}

.navSlide.full .navBox .navSlideHeader .navToggle .showNav {
  visibility: visible;
  height: auto;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed:after {
  width: 7px;
  height: 7px;
  transform: rotate(-45deg);
  position: relative;
  top: 0px;
  border-left: 0px solid #393939;
  border-bottom: 1px solid #393939;
  border-top: 0px solid #393939;
  border-right: 1px solid #393939;
}

.navSlide.full
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button:after {
  visibility: visible;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button:after {
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  position: relative;
  top: -3px;
  border-left: 0px solid #fae345;
  border-bottom: 1px solid #fae345;
  border-top: 0px solid #fae345;
  border-right: 1px solid #fae345;
  visibility: hidden;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed {
  background: #fff;
  height: 50px;
  border-radius: 0 30px 30px 0px;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed
  .navTitle {
  color: #393939;
}

.navSlide.full
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  .accordion-collapse.collapse {
  border-bottom: 1px solid #e6e6e6;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .accordion-item
  .accordion-collapse.collapse {
  border-bottom: 0px solid #e6e6e6;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button:after {
  position: absolute;
  left: 60px;
  transform: rotate(45deg);
  top: 10px;
  border-color: #270089;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button
  .navTitle {
  color: #270089;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed:after {
  transform: rotate(-45deg);
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button.collapsed
  .navTitle {
  color: #696969;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  h2.accordion-header
  a.accordion-button {
  background: #fff;
  height: 50px;
  border-radius: 0 30px 30px 0px;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  a.accordion-button
  .navTitle {
  color: #270089;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  a.accordion-button:after {
  transform: rotate(-45deg);
  position: absolute;
  left: 60px;
  top: 10px;
  border-color: #270089;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  a.accordion-button.collapsed
  .navTitle {
  color: #696969;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  .accordion-item
  .accordion-collapse.collapse {
  border-bottom: 0;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  a.notCollapsed.accordion-button {
  padding: 10px 0 10px 80px;
  background: transparent;
  box-shadow: 0 0 0 0;
  position: relative;
  text-decoration: none;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  a.notCollapsed.accordion-button
  .navTitle {
  color: #696969;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  a.notCollapsed.accordion-button.active
  .navTitle {
  color: #270089;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  a.notCollapsed.accordion-button::after {
  width: 7px;
  height: 7px;
  transform: rotate(-45deg);
  top: 18px;
  border-left: 0px solid #696969;
  border-bottom: 1px solid #696969;
  border-top: 0px solid #696969;
  border-right: 1px solid #696969;
}
.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  a.notCollapsed.accordion-button.active::after {
  border-left: 0px solid #393939;
  border-bottom: 1px solid #393939;
  border-top: 0px solid #393939;
  border-right: 1px solid #393939;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  a.notCollapsed.accordion-button.innerCat {
  padding: 5px 0 5px 100px;
  margin: 5px 0;
}

.navSlide
  .navBox
  .navSlideBody
  .mainCat.accordion
  .subCat.accordion
  a.notCollapsed.accordion-button.innerCat:after {
  left: 75px;
  top: 12px;
}

/* menu full css */
.navSlide.full {
  width: 363px;
  height: 100vh;
  border-right: 1px solid #e6e6e6;
}

.navSlide.full .navBox .navSlideHeader {
  padding-right: 40px;
}

/* Hover css */
/* .navSlide:hover {
    width: 363px;
    overflow: hidden;
}

.navSlide:hover .navBox .navSlideBody .mainCat.accordion .accordion-item .accordion-collapse .accordion-body {
    width: 0px;
    transition: 0.5s ease-in-out;
}
.navSlide:hover .navBox .navSlideBody .mainCat.accordion .accordion-item .accordion-collapse .accordion-body {
    display: block;
    width: 300px;
}

.navSlide:hover .navBox .navSlideBody .mainCat.accordion .accordion-item h2.accordion-header .navTitle {
    display: block;
} */
