.Login {
    height: 111.1vh;
    width: 100%;
    background-image: url(../images/login-bg.png);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    position: relative;
}
.Login .container {
    z-index: 1;
}
.Login:before {
    content: "";
    background-image: url(../images/top-right-bg.png);
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    opacity: 0.4;
}
.Login:after {
    content: "";
    background-image: url(../images/left-bottom-bg.png);
    width: 100%;
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    opacity: 0.4;
}

.loginBox {
    border-radius: 10px;
    overflow: hidden;
    width: 542px;
    margin: 0 auto;
}

.loginBox .loginBoxHeader {
    background: linear-gradient(267deg, #236092 0.88%, #270089 96.87%);
    height: 90px;
    text-align: center;
    position: relative;
    box-shadow: 0px 6px 12px 0px #00000026;

}
.loginBox .loginBoxHeader:before {
    content: "";
    background-image: url(../images/login-header-bg.png );
    width: 410px;
    height: 100px;
    position: absolute;
    opacity: 0.4;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100%;
    right: -10px;
}

.loginBox .loginBoxHeader img {
    margin: 15px 0 0;
}

.loginBox .loginBoxContent {
    background: #fff;
    padding: 40px;
    margin-top: -10px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 10px 20px 0px #00000026;
}

.loginBox .loginBoxContent h2 {
    font-family: 'Manrope', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    background: -webkit-linear-gradient(135deg, #236092 0.88%, #270089 96.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.loginBox .loginBoxContent p {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #393939;
    margin: 0 0 15px;
}

form.loginForm label.form-label {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #393939;
}
form.loginForm input.form-control {
    border: 1.5px solid #979797;
    height: 50px;
}
form.loginForm input.form-control::placeholder {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #979797;
}

form.loginForm .inputBox {
    position: relative;
}

form.loginForm .inputBox .viewIcon {
    position: absolute;
    right: 15px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

form.loginForm button.btn {
    background: #270089;
    border-radius: 30px;
    height: 50px;
    color: #FAE345;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    position: relative;
    margin-bottom: 15px;
}
form.loginForm button.btn:before {
    content: "";
    background-image: url(../images/login-btn-bg.png);
    position: absolute;
    width: 120%;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0px;
    height: 70px;
    margin-top: -15px;
    margin-right: -30px;
    opacity: 0.2;
}

form.loginForm a.linktext {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #236092;
}


.otpInput {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.otpInput input {
    width: 50px;
    margin: 0 10px;
    height: 50px;
    border-radius: 6px;
    border: 1.5px solid #979797;
    box-shadow: 0 0 0 0;
    outline: 0;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #270089;
}
.loginBox .loginBoxContent .resendRow{display: flex; justify-content: space-between; align-items: center;padding: 0 30px;font-family: Manrope;margin-bottom: 40px;}
.loginBox .loginBoxContent .resendRow .resendButton{background:none; border:none; padding:0px; color:#0F042D; font-weight: bold; font-size: 16px;}
.loginBox .loginBoxContent .resendRow .resendButton[disabled]{opacity: 0.3;}
.loginBox .loginBoxContent .resendRow .resendTimer{margin:0;}

.loginBox .loginBoxContent .errorRow{display: none; justify-content: center; align-items: center; font-family: Manrope; margin: 10px 0px 0px;}
.loginBox .loginBoxContent .errorRow svg{width:22px; height:22px;}
.loginBox .loginBoxContent .errorRow span{font-weight: 600; font-size: 16px; margin:0px 0px 0px 10px; display: none;}
.loginBox .loginBoxContent .errorRow.resendMsg,
.loginBox .loginBoxContent .errorRow.invalidMsg{display: flex;}
.loginBox .loginBoxContent .errorRow.resendMsg svg{fill:#236092;}
.loginBox .loginBoxContent .errorRow.resendMsg span.resendMsg{display:block;color:#236092;}
.loginBox .loginBoxContent .errorRow.invalidMsg svg{fill:#A80000;}
.loginBox .loginBoxContent .errorRow.invalidMsg span.invalidMsg{display:block;color:#A80000;}

form.loginForm label.form-label.otp {
    width: 100%;
    font-family: Manrope;
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: center;
    color: #270089;
    margin: 0;
}
.loginBox .loginBoxContent p.otpNote {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #31373D;
    margin-bottom: 50px;
}


.loginBox .loginBoxContent p.exhaustNote {

    font-family: Manrope;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #31373D;
    margin-top: 30px;
    margin-bottom: 50px;
}

.loginBox .loginBoxContent p.exhaustNoteSub {

    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #999999;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
}

.exhaust-link{
font-family: Manrope;
font-size: 16px;
font-weight: 700;
line-height: 30px;
letter-spacing: -0.025em;
text-align: left;
color:#236092;
margin-top: 20px;
;

}