
/* Bread Crumb */ 
.breadCrumb {
    background: #F4F2F9;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.breadCrumb ul {
    display: flex;
    align-items: center;
    justify-content: start;
    list-style: none;
    padding: 0;
    margin: 0 0 0 -20px;
    height: 100%;
}
.breadCrumb ul li {position: relative;}
.breadCrumb ul li a {
    padding: 0 20px;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #696969;
    text-decoration: none;
}
.breadCrumb ul li a:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 6px;
    transform: rotate(45deg);
    left: -5px;
    border-top: 2px solid #270089;
    border-right: 2px solid #270089;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
}
.breadCrumb ul li:first-child a:after {
    content: "";
    width: 0;
    border: 0;
}
.breadCrumb ul li:first-child a:after {
}
.breadCrumb ul li a.active {
    color: #270089;
    font-weight: 700;
    position:relative;
}

.breadCrumb .backButton-dv {
    position: relative;
    top: -2px;
}

.breadCrumb .backButton-dv button.btn.backBtn {
    padding: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0;
}
