/* Theme btns */
button.btn.btn-success {
  background-color: #009028;
  --bs-btn-border-color: #009028;
  --bs-btn-hover-bg: #009028;
  --bs-btn-color: #fff;
  border-radius: 40px;
}
button.btn.btn-warning {
  background-color: #e57c00;
  --bs-btn-border-color: #e57c00;
  --bs-btn-hover-bg: #e57c00;
  --bs-btn-color: #fff;
}
button.outline-button {
  border: 1px #270089;
  border-radius: 5px;
  height: 50px;
  padding: 0 34px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #270089;
}

/* Dashboard */
.dashoboard {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  overflow: hidden;
}

.dashoboard .component {
  flex-grow: 1;
  height: 110vh;
  width: calc(100% - 363px);
  overflow-y: scroll;
  overflow-x: hidden;
}

/* content Option Box */
.content {
  margin: 20px;
}

.content .title {
  font-family: Manrope;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
}

.content .optionsBox {
  margin-bottom: 15px;
}

.content .optionsBox .searchBox {
}

.content .optionsBox .searchBox form.searchForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 357px;
  height: 40px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 0 15px;
}

.content .optionsBox .searchBox form.searchForm input#serachInput {
  flex-grow: 1;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 0 0;
}

.content .optionsBox .searchBox form.searchForm button.btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

.content .optionsBox .pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 40px;
}

.content .optionsBox .pagination input#pageCount {
  border: 1px solid #e6e6e6;
  width: 40px;
  height: 26px;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 5px 0 0;
}

.content .optionsBox .pagination span.sep {
  margin: 0 5px 0 0;
}

.content
  .optionsBox
  .pagination
  :is(span.showRecord, span.of, span.totalRecord) {
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
  margin: 0 3px;
}

.content .optionsBox .pagination :is(span.prePage, span.nextPage) {
  margin: 0 2px;
}

.content .optionsBox span.reloadList {
  border: 1.5px solid #696969;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.page-title {
  font-family: Manrope;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
}

.duration {
}

.duration form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dateInputDv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 124px;
  border: 1px solid #9c9a9a;
  padding: 0 6px;
  margin: 0 15px;
  height: 26px;
  overflow: hidden;
  border-radius: 5px;
}

.dateInputDv input {
  border: 0;
  box-shadow: 0 0 0 0;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #7c7c7c;
}

.dateInputDv svg {
  margin-right: 8px;
  display: none;
}

.duration h5 {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 0;
}

.duration button.btn {
  box-shadow: 0px 10px 20px 0px #00000026;
  background: linear-gradient(135deg, #236092 0.88%, #270089 96.87%);
  height: 26px;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  line-height: 1;
}

/* records */
.records {
  position: relative;
}

.records.vertical-scroll {
  height: 187px;
  overflow-y: scroll;
}

.records.vertical-scroll.active-loans-scroll {
  height: auto;
  overflow-y: auto;
  width: 100%;
}
.records.vertical-scroll.active-loans-scroll table.table tbody {
  height: 210px;
  display: block;
  /* width: calc(100% + 15px); */
}
.records.vertical-scroll.active-loans-scroll table.table tbody {
  display: block;
  /* height:200px; */
  overflow: auto;
}
.records.vertical-scroll.active-loans-scroll table.table thead,
.records.vertical-scroll.active-loans-scroll table.table tbody tr {
  display: table;
  width: 100%;
}

.records .table-responsive {
}

.records .table-responsive table.scroll-h {
  width: 140%;
}
.records .table-responsive table.table {
  min-height: 280px;
}

.records .table-responsive table.table.widthCustom20 {
  width: 2000px;
}

.records .table-responsive table.table.widthCustom24 {
  width: 2400px;
}

.records .table-responsive table.table thead {
  height: 66px;
}

.records .table-responsive table.table thead tr {
}

.records .table-responsive table.table thead tr th {
  background: #f4f2f9;
  border-bottom: 0;
  vertical-align: middle;
  padding: 10px 12px;
}

.records .table-responsive table.table thead tr th .labelIco {
  height: 40px;
  border-right: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 0 15px 0 5px; */
}

.records .table-responsive table.table thead tr th .labelIco span.headTitle {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
}

.records .table-responsive table.table thead tr th .labelIco span.icon {
  margin-left: 10px;
}

.records .table-responsive table.table thead tr th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.records .table-responsive table.table thead tr th:last-child {
  border-radius: 0 10px 10px 0;
}

.records .table-responsive table.table thead tr th .labelIco .statusDropdown {
}

.records
  .table-responsive
  table.table
  thead
  tr
  th
  .labelIco
  .statusDropdown
  button.dropdown-toggle {
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
}

.records
  .table-responsive
  table.table
  thead
  tr
  th
  .labelIco
  .statusDropdown
  button.dropdown-toggle:after {
  display: none;
}

.records
  .table-responsive
  table.table
  thead
  tr
  th
  .labelIco
  .statusDropdown
  .dropdown-menu {
  border: 1px solid #e6e6e6;
  border-radius: 10px 0px 10px 10px;
  box-shadow: 0px 10px 20px 0px #00000026;
}

.records
  .table-responsive
  table.table
  thead
  tr
  th
  .labelIco
  .statusDropdown
  .dropdown-menu
  a.dropdown-item {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
  transition: 0.2s ease-in-out;
}

.records
  .table-responsive
  table.table
  thead
  tr
  th
  .labelIco
  .statusDropdown
  .dropdown-menu
  a.dropdown-item:hover {
  background: #f4f2f9;
  color: #270089;
  font-weight: 700;
}

.records .table-responsive table.table tbody {
}

.records .table-responsive table.table tbody tr {
}

.records .table-responsive table.table tbody tr:nth-child(even) td {
  background: #f9f9f9;
}

.records .table-responsive table.table tbody tr td {
  font-size: 14px;
  color: #000;
  vertical-align: middle;
  padding: 10px 12px;
}

.records.activeLoanApp .table-responsive table.table {
  min-height: auto;
}

.records.activeLoanApp .table-responsive {
  min-height: 280px;
}

.records .table-responsive table.table tbody tr td .dateTime {
  font-family: "Manrope", sans-serif;
}

.records .table-responsive table.table tbody tr td .dateTime.sep {
  display: block;
}
.records .table-responsive table.table tbody tr td .dateTime.sep span.date {
  border-bottom: 1px solid #000;
}

.records .table-responsive table.table tbody tr td .dateTime span.date {
  font-size: 14px;
  color: #000;
}

.records .table-responsive table.table tbody tr td .dateTime span.time {
  font-size: 12px;
  color: #696969;
}

.records .table-responsive table.table tbody tr td .userId {
  color: #000;
}

.records .table-responsive table.table tbody tr td .name {
}

.records .table-responsive table.table tbody tr td .walletBalance {
}

.records
  .table-responsive
  table.table
  tbody
  tr
  td
  .walletBalance
  span.currency {
  color: #236092;
  font-size: 9px;
  position: relative;
  top: -4px;
  margin-left: 5px;
}

.records .table-responsive table.table tbody tr td .status {
  display: flex;
  align-items: center;
}

.records .table-responsive table.table tbody tr td .status button.btn {
  border-radius: 30px;
  padding: 0px 20px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.records .table-responsive table.table tbody tr td .from {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
}

.records .table-responsive table.table tbody tr td .from .fromACcount {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.records .table-responsive table.table tbody tr td .from .fromAmount {
}

.records .table-responsive table.table tbody tr td .from .fromAmount span {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
}

.records .table-responsive table.table tbody tr td .status span.dot {
  width: 8px;
  height: 8px;
  display: block;
  background: #009028;
  border-radius: 50%;
  margin-right: 5px;
}

.records .table-responsive table.table tbody tr td .status span.dot.banned,
.records .table-responsive table.table tbody tr td .status span.dot.suspended {
  background-color: #a80000;
}
.records .table-responsive table.table tbody tr td .status span.dot.inactive,
.records .table-responsive table.table tbody tr td .status span.dot.pending {
  background: #ffa800;
}

.records .table-responsive table.table tbody tr td .action {
}

.records .table-responsive table.table tbody tr td .action button.btn.btn-view,
.records .table-responsive table.table tbody tr td .action a.btn.btn-view {
  width: 54px;
  height: 28px;
  border-radius: 51px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 6px;
  letter-spacing: 0em;
  text-align: center;
  color: #270089;
  border: 1px solid #270089;
  text-decoration: none;
}
.records
  .table-responsive
  table.table
  tbody
  tr
  td
  .action
  button.btn.btn-view:focus,
.records
  .table-responsive
  table.table
  tbody
  tr
  td
  .action
  button.btn.btn-view:hover {
  background: transparent;
}

.records .table-responsive table.table tbody tr td .sendNotifi {
}
.records .table-responsive table.table tbody tr td .sendNotifi button {
  width: 32px;
  padding: 5px 5px;
  border-radius: 51px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 6px;
  letter-spacing: 0em;
  text-align: center;
  color: #270089;
  border: 1px solid #270089;
  background: none;
  text-decoration: none;
}
.records .table-responsive table.table tbody tr td .sendNotifi button svg {
  width: 20px;
  height: 20px;
  stroke: #270089;
}
.checkBoxesRow {
  display: flex;
}
.checkBoxesRow .form-check {
  padding: 0;
}
.checkBoxesRow .form-check + .form-check {
  margin-left: 15px;
}
.checkBoxesRow .form-check input.form-check-input {
  border: 1.25px solid #d0d5dd;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 0;
  margin: 2px 5px 0px 0px;
}
.checkBoxesRow .form-check input.form-check-input:checked {
  background-color: #00893a;
  border: 1.25px solid;
  border-image-source: linear-gradient(60.23deg, #2fb568 0.2%, #00893a 99.99%);
}
.checkBoxesRow .form-check input.form-check-input:checked + label {
  font-weight: bold;
  text-shadow: 0px 0px 10px #00b221;
}

.modal .modal-dialog .modal-body .quoteBox {
  background: #f7f7f7;
  padding: 30px 50px;
  margin: 0px 0px 30px;
  text-align: center;
}
.modal .modal-dialog .modal-body .quoteBox span {
  font-style: italic;
  font-size: 26px;
  line-height: 2;
  opacity: 0.5;
}
.modal .modal-dialog .modal-body .quoteBox span:before,
.modal .modal-dialog .modal-body .quoteBox span:after {
  content: "\0022";
}

.notifyNoRecord {
  width: 100%;
  text-align: center;
  color: #696969a3;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  position: absolute;
  top: 80px;
}

/* Borrower */
.borrowers {
  margin: 20px;
}

.borrowers .borrowerInfoBox {
  padding: 40px;
  background: linear-gradient(135deg, #236092 0.88%, #270089 96.87%);
  border-radius: 15px;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0px 10px 20px 0px #00000026;
}
.borrowers .borrowerInfoBox:after {
  content: "";
  position: absolute;
  background-image: url(../../images/counter-bg.png);
  background-size: contain;
  background-position: right bottom;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  opacity: 0.1;
  top: 0;
  right: 0;
  z-index: 0;
}

.borrowers .borrowerInfoBox .borrowerStatusBar {
  background: #fff;
  border-radius: 5px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 30px;
  box-shadow: 0px 10px 20px 0px #00000026;
  position: relative;
  z-index: 1;
}

.borrowers .borrowerInfoBox .borrowerStatusBar ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0 20px;
  height: 57px;
}

.borrowers .borrowerInfoBox .borrowerStatusBar ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 30px 0 0;
}

.borrowers .borrowerInfoBox .borrowerStatusBar ul li .label {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: #696969;
  margin-right: 15px;
  font-family: Manrope;
}

.borrowers .borrowerInfoBox .borrowerStatusBar ul li .value {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  font-family: Manrope;
}

.borrowers .borrowerInfoBox .borrowerStatusBar ul li .value span.dot {
  width: 8px;
  height: 8px;
  background: green;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
}

.borrowers .borrowerInfoBox .borrowerStatusBar ul li .value span.title {
}
.borrowers .borrowerInfoBox .borrowerStatusBar ul li .value.green span.title {
  color: #009028;
  font-weight: 700;
}

.borrowers .borrowerInfoBox .borrowerInfo {
  position: relative;
  z-index: 1;
}

.borrowers .borrowerInfoBox .borrowerInfo .infoBox {
}

.borrowers .borrowerInfoBox .borrowerInfo .infoBox h5 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.borrowers .borrowerInfoBox .borrowerInfo .infoBox h4 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fae345;
  overflow: hidden;
  text-overflow: ellipsis;
}
.borrowers .borrowerInfoBox .borrowerInfo .infoBox h4 span.time {
  font-size: 13px;
  color: #fff;
}

.borrowers .borrowerInfoBox .borrowerInfo .infoBox h4 span.date {
  margin-right: 6px;
}

.borrowers .borrowerInfoBox .borrowerInfo .btnBox {
  display: flex;
  align-items: end;
  height: 100%;
  justify-content: end;
}

.borrowers .borrowerInfoBox .borrowerInfo .btnBox button.btn {
  background: transparent;
  border: 1px solid #fff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 10px 15px;
  min-width: 168px;
}

/* Borrower Tabs */
.borrowers .borrowerTabs {
}

.borrowers .borrowerTabs ul.nav.nav-tabs {
  width: calc(100% - 15px);
  overflow: hidden;
  position: relative;
}

.borrowers .borrowerTabs ul.nav.nav-tabs li.nav-item {
  margin: 0;
  background: transparent;
}

.borrowers
  .borrowerTabs
  ul.nav.nav-tabs
  li.nav-item:first-child
  button.nav-link {
  border-left: 2px solid #e6e6e6;
  border-radius: 5px 15px 0 0;
}

.borrowers .borrowerTabs ul.nav.nav-tabs li.nav-item button.nav-link {
  border: 0;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #270089;
  height: 50px;
  min-width: 150px;
  border: 2px solid #e6e6e6;
  border-radius: 0px 15px 0 0;
  border-left: 0px;
  position: relative;
  background: #fff;
  z-index: 2;
}

.borrowers .borrowerTabs ul.nav.nav-tabs li.nav-item button.nav-link.active {
  /* background: linear-gradient(135deg, #236092 0.88%, #270089 96.87%); */
  color: #fff;
  position: relative;
  background-color: transparent;
  border: 1px solid #ccc;
  z-index: 1;
}

.borrowers .borrowerTabs ul.nav.nav-tabs li.nav-item button.nav-link:after {
  content: "";
  width: 98%;
  height: 17px;
  border-top: 2px solid #e6e6e6;
  left: -9px;
  position: absolute;
  top: -2px;
  /* background: rebeccapurple; */
  z-index: -2;
}

.borrowers
  .borrowerTabs
  ul.nav.nav-tabs
  li.nav-item
  button.nav-link.active:before {
  content: "";
  width: calc(150px + 50px);
  height: 100%;
  background: linear-gradient(135deg, #236092 0.88%, #270089 96.87%);
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  border-radius: 0px 15px 0px 0px;
}

.borrowers .borrowerTabs .tab-content {
  border: 2px solid #e6e6e6;
  position: relative;
  top: -2px;
  min-height: 100px;
  border-radius: 0px 15px 15px 15px;
}

/* font-family: 'Cairo', sans-serif; */
/* tabs Personal Details */
.personal-details {
  margin: 30px 0px 30px 30px;
  border-right: 1px solid #e6e6e6;
  padding-right: 50px;
  position: relative;
  right: -1px;
}

.personal-details h3 {
  font-family: "Manrope", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin-bottom: 20px;
}
.personal-details h3 {
  font-family: "Cairo", sans-serif;
}

.personal-details .identityBox {
  margin-bottom: 15px;
}

.personal-details .identityBox h4 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 5px;
}

.personal-details .identityBox h4 span.small {
  font-size: 10px;
  line-height: 13.66px !important;
}

.personal-details .identityBox h5 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin: 0;
}

.personal-details .identityBox h4.arabi {
  font-family: "Cairo", sans-serif;
}
.personal-details .identityBox h5.arabi {
  font-family: "Cairo", sans-serif;
}

/* Contact Details */
.detailBox {
}

.detailBox.separator-right {
  border-right: 1px solid #e6e6e6;
  height: 100%;
  margin-right: 25px;
}

.detailBox h3 {
  font-family: "Manrope", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin-bottom: 20px;
}

.detailBox .identityBox {
  margin-bottom: 15px;
}

.detailBox .identityBox h4 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 5px;
}

.detailBox .identityBox a.linkText {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin: 0;
  text-decoration: none;
  word-wrap: break-word;
}

.contact-details {
  padding: 10px 30px 30px 30px;
}

.detailBox .identityBox h5 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin: 0;
}
.detailBox .identityBox h5.arabi {
  font-family: "Cairo", sans-serif;
}

/* Modal */
.banForm {
}

.banForm .modal-dialog {
}

.banForm.confirm {
  background: #0e234a80;
  -webkit-backdrop-filter: saturate(190%) blur(8px);
  backdrop-filter: saturate(190%) blur(8px);
  opacity: 1;
}
.banForm.confirm .modal-dialog {
  min-width: 500px;
}

.banForm .modal-dialog .modal-content {
  padding: 40px;
}

.banForm .modal-dialog .modal-content .modal-header {
  border: 0;
  padding: 0 0 20px;
}

.banForm .modal-dialog .modal-content .modal-header .modal-title {
  font-family: "Manrope", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #270089;
}

.banForm .modal-dialog .modal-content .sub-title {
  font-family: "Manrope", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
}

.banForm .modal-dialog .modal-content .modal-body {
  padding: 0;
  border: 0;
}

.banForm .modal-dialog .modal-content .modal-body form {
}

.banForm .modal-dialog .modal-content .modal-body form .popupDv {
}

.banForm .modal-dialog .modal-content .modal-body form .popupDv .dateTime {
  display: flex;
}

.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  .dateTime
  span.time {
  margin-left: 5px;
}

.banForm .modal-dialog .modal-content .modal-body form .popupDv h4 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
  margin: 0;
}

.banForm .modal-dialog .modal-content .modal-body form .popupDv h5 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin: 0;
  text-transform: capitalize;
}

.banForm .modal-dialog .modal-content .modal-body form .popupDv .status {
  display: flex;
  align-items: center;
}

.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  .status
  span.dot {
  width: 8px;
  height: 8px;
  display: block;
  background: #009028;
  border-radius: 50%;
  margin-right: 5px;
}

.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  .status
  span.dot.banned {
  background-color: #a80000;
}

.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  label.form-label {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
  margin: 0;
}

.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  select.form-select {
  width: 210px;
  height: 50px;
  border: 1px solid #979797;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #270089;
  box-shadow: 0 0 0 0;
}

.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  textarea.form-control {
  border: 1px solid #979797;
  height: 140px;
  border-radius: 5px;
  resize: none;
  box-shadow: 0 0 0 0;
  padding: 15px;
}
.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  .form-control::placeholder {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #979797;
}

.banForm .modal-dialog .modal-content button.btn.btn-primary {
  background: #270089;
  border: 1px solid #270089;
  width: 148px;
  height: 50px;
}
.banForm .modal-dialog .modal-content button.btn.btn-danger {
  background: #a80000;
  border: 1px solid #a80000;
}
.banForm .modal-dialog .modal-content .modal-body form button.btn,
.banForm .modal-dialog .modal-content button.btn {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  font-family: "Manrope", sans-serif;
}

.banForm .modal-dialog .modal-content .modal-body form button.btn.btn-white,
.banForm .modal-dialog .modal-content button.btn.btn-white {
  background: #fff;
  color: #270089;
}

button.btn-close {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.5837 13.5458L13.0385 8.99957L17.5823 4.45475C18.1378 3.8992 18.1378 2.99083 17.5823 2.43505L15.5604 0.415624C15.0049 -0.138541 14.0968 -0.138541 13.5414 0.415624L8.99729 4.96044L4.45366 0.415624C3.89962 -0.138541 2.99006 -0.138541 2.43441 0.415624L0.416814 2.43505C-0.138612 2.9906 -0.138612 3.89898 0.416814 4.45475L4.96067 8.99961L0.416814 13.5458C-0.138612 14.1014 -0.138612 15.0097 0.416814 15.5652L2.43606 17.5833C2.99149 18.1389 3.901 18.1389 4.45504 17.5833L8.9989 13.0401L13.5425 17.5833C14.0979 18.1389 15.0061 18.1389 15.5645 17.5833L17.5837 15.5652C18.1391 15.0097 18.1391 14.1013 17.5837 13.5458Z' fill='%23A5A5A5'/%3e%3c/svg%3e");
  border: 0;
  box-shadow: 0 0 0;
  opacity: 1;
  font-size: 20px;
}
button.btn-close:focus:not(:focus-visible) {
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 0;
}

.banForm .modal-dialog .modal-content .modal-footer {
  border: 0;
}

.banForm
  .modal-dialog
  .modal-content
  .modal-body
  form
  .popupDv
  textarea.form-control:focus {
}

.banForm
  .modal-dialog
  .modal-content
  .records
  .table-responsive
  table.table
  tbody
  tr
  td {
  padding: 20px 13px;
}

/* Account Summary */
.accountSummary {
  padding: 30px 30px 0;
  font-family: "Manrope", sans-serif;
}

.accountSummary .optionsBox {
}

.accountSummary .optionsBox .searchBox {
  display: flex;
  justify-content: end;
}

.accountSummary .optionsBox .searchBox .searchForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 357px;
  height: 40px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 0 15px;
}

.accountSummary .optionsBox .searchBox .searchForm input#serachInput {
  flex-grow: 1;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.accountSummary .optionsBox .searchBox .searchForm button.btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

.sort {
  border: 1px solid #e6e6e6;
  height: 40px;
  padding: 0 0px 0 15px;
  border-radius: 5px;
}

.sort .title {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #696969;
  word-break: normal;
  display: block;
  min-width: 80px;
}

.sort select.form-select {
  border: 0;
  box-shadow: 0 0 0 0;
}

.sort .sortDropdown {
}

.sort .sortDropdown button#dropdown-basic {
  background: transparent;
  color: #000;
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
}

.sort .sortDropdown button#dropdown-basic::after {
  content: "";
  border-left: 0px solid #000;
  border-top: 0px solid #000;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  width: 6.5px;
  height: 6.5px;
  transform: rotate(45deg);
  position: relative;
  top: -2px;
}

.sort .sortDropdown .dropdown-menu {
  border: 1px solid #e6e6e6;
  box-shadow: 0px 10px 20px 0px #00000026;
  width: 151px;
  border-radius: 0 0 10px 10px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
}

.sort .sortDropdown .dropdown-menu a.dropdown-item {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
  transition: 0.2s ease-in-out;
}

.sort .sortDropdown .dropdown-menu a.dropdown-item:hover {
  background: #f4f2f9;
  color: #270089;
  font-weight: 700;
}

.accSumBox {
  border: 2px solid #e6e6e6;
}

.accountSummary h2 {
  color: #270089;
  font-size: 25px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.accountSummary h3 {
  color: #270089;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.accountSummary .summaryBox {
}

.accountSummary .summaryBox h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
  margin: 0 0 5px;
  white-space: nowrap;
}

.accountSummary .summaryBox .dateTime {
  display: flex;
  align-items: end;
  justify-content: flex-start;
}

.accountSummary .summaryBox .dateTime h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin: 0 5px 0 0;
}

.accountSummary .summaryBox .dateTime span.time {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}

.accountSummary .summaryBox h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin: 0;
}
button.btn.btn-certificate:focus {
  background: transparent;
  color: #270089;
}

button.btn.btn-certificate {
  background: #fff;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #270089;
  width: 205px;
  height: 50px;
  border: 1px solid #270089;
}

button.btn.btn-certificate .icon {
  margin-right: 5px;
}

.accountSummary .walletBox {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: end;
}

.accountSummary .walletBox a.btn.btn-wallet {
  background: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #270089;
  border: 1px solid #270089;
  height: 28px;
  border-radius: 50px;
}

/* Action Modals */
.adminUserModal.modal {
}

.adminUserModal.modal .modal-content {
}

.adminUserModal.modal .modal-content .modal-header {
  border: 0;
  padding: 50px 50px 0px;
}

.adminUserModal.modal .modal-content .modal-header .modal-title {
  font-family: Manrope;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #270089;
}

.adminUserModal.modal .modal-content .modal-header button.btn-close {
  display: none;
}

.adminUserModal.modal .modal-content .modal-body {
  padding: 30px 50px 50px;
}

.adminUserModal.modal .modal-content .modal-body form {
}

.adminUserModal.modal .modal-content .modal-body form input.form-control {
  border: 1.5px solid #979797;
  height: 50px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
}

.adminUserModal.modal .modal-content .modal-body form select.form-select {
  border: 1.5px solid #979797;
  height: 50px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
}

.adminUserModal.modal .modal-content .modal-body form label.form-label {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}

.adminUserModal.modal .modal-content .modal-body form .btnGroup {
}

.adminUserModal.modal .modal-content .modal-body form .btnGroup button.btn {
  border: 1.5px solid #270089;
  background-color: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #270089;
  height: 50px;
  width: 158px;
}

.adminUserModal.modal
  .modal-content
  .modal-body
  form
  .btnGroup
  button.btn.btn-primary {
  background: #270089;
  color: #fff;
}

.adminUserModal.modal
  .modal-content
  .modal-body
  form
  .btnGroup
  button.btn.btn-secondary {
}

.dataBox {
}

.dataBox h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
  margin: 0 0 5px;
  white-space: nowrap;
}

.dataBox .dateTime {
  display: flex;
  align-items: end;
  justify-content: flex-start;
}

.dataBox .dateTime h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #270089;
  margin: 0 5px 0 0;
}

.dataBox .dateTime span.time {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}

.select-dv {
  width: 100%;
  height: auto;
  position: relative;
}

.select-dv:before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  background: green;
  border-radius: 50%;
  top: 21px;
  left: 10px;
}

.select-dv.banned:before {
  background: #a80000;
}

.select-dv select.form-select {
  padding-left: 25px;
}

.adminUserModal.modal
  .modal-content
  .modal-body
  form
  select.form-select[disabled] {
  background: #fff;
  border-color: #ebebeb;
}
.adminUserModal.modal
  .modal-content
  .modal-body
  form
  input.form-control:disabled {
  background: #fff;
  border-color: #ebebeb;
}

.adminUserModal.modal .modal-content button.editAdminUser.btn.btn-link {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: #270089;
  padding: 0;
}

/* Accounts Dv Form Start */
.accountAction-dv {
}

.accountAction-dv form {
}

.accountAction-dv form label.form-label {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}

.accountAction-dv form select.form-select {
  border: 1px solid #979797;
  height: 50px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #979797;
  box-shadow: 0 0 0 0;
}

.accountAction-dv form input.form-control {
  border: 1px solid #979797;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #979797;
  box-shadow: 0 0 0 0;
}

.accountAction-dv form button.btn.btn-primary {
  background: #270089;
  height: 50px;
  width: 148px;
  border-color: #270089;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
}

.accountAction-dv form textarea.form-control {
  height: 140px;
  text-align: left;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #979797;
  padding: 10px;
  box-shadow: 0 0 0 0;
}
.accountAction-dv form textarea.form-control::placeholder {
  color: #979797;
}
/* Accounts Dv Form End  */

/* User Pep Modal Start */
.userPepModal {
}

.userPepModal .modal-content {
  padding: 50px;
}

.userPepModal .modal-content .modal-body {
  padding: 0;
  margin-bottom: 30px;
}

.userPepModal .modal-content .modal-body h3 {
  font-family: Manrope;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #270089;
}

.userPepModal .modal-content .modal-footer {
  justify-content: center;
  border: 0;
  padding: 0;
}

.userPepModal .modal-content .modal-footer .btn.btn-secondary {
  border: 1.5px solid #270089;
  background: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #270089;
  height: 50px;
  min-width: 148px;
}

.userPepModal .modal-content .modal-footer .btn.btn-primary {
  background: #270089;
  height: 50px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  min-width: 148px;
}

.userPepModal .modal-dialog {
  max-width: 694px;
}

/* User Pep Modal End */

/* tooltip start */
.verify-dv {
  display: flex;
  align-items: center;
}

.verify-dv .badge.bg-success {
  background-color: #00902833 !important;
  color: #044e18;
}

.verify-dv .badge.bg-danger {
  background-color: #a8000033 !important;
  color: #a80000;
}

.verify-dv .badge.bg-danger a svg path {
  fill: #a80000;
}

.verify-dv .badge {
  font-family: Manrope;
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  height: 21px;
  margin-left: 5px;
  border-radius: 4px;
}

.verify-dv .badge a {
  margin-left: 4px;
  display: inline-block;
  position: relative;
  top: -1px;
}
/* tooltip end */

/* Role management screens */
/* dashboard Start */
.permission-check {
  text-align: center;
  width: 100px;
  /* background: rebeccapurple; */
}

.permission-check input.form-check-input {
  border: 1.25px solid #d0d5dd;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 0;
}

.permission-check input.form-check-input:checked {
  background-color: #00893a;
  border: 1.25px solid;
  border-image-source: linear-gradient(60.23deg, #2fb568 0.2%, #00893a 99.99%);
}

button.btn-theme-secondary.btn {
  background-color: #979797;
  height: 50px;
  min-width: 204px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}

button.btn-theme-secondary.active.btn {
  background-color: #270089;
}

button.btn-theme-secondary.btn:focus {
  background-color: #270089;
  color: #fff;
}

.userPepModal.permissionModal .modal-dialog {
  max-width: 780px;
}
/* dashboard End */

/* Edit Profile Modal Start */
.banForm.editProfile .modal-dialog {
  --bs-modal-width: 920px;
}

.banForm.editProfile {
}

.banForm.editProfile .modal-dialog {
  --bs-modal-width: 920px;
}

.modal .modal-dialog {
}

.modal .modal-dialog .modal-body {
}

.modal .modal-dialog .modal-body hr.dvdr {
  border-top: 1.5px solid #7c7c7c;
  margin: 0px 0 30px;
}

.modal .modal-dialog .modal-body .imageUpload {
  width: 129px;
  height: 129px;
  overflow: hidden;
  position: relative;
}

.modal .modal-dialog .modal-body .imageUpload .imageBg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal .modal-dialog .modal-body .imageUpload .imageBg img {
}

.modal .modal-dialog .modal-body .imageUpload input[type="file"] {
  position: absolute;
  width: 300%;
  height: 210px;
  left: -110%;
  top: -60px;
  background: transparent;
}

.modal .modal-dialog .modal-body .imageUpload span.label {
  position: absolute;
  bottom: 0;
  background: #000000cc;
  width: 100%;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  height: 26px;
  line-height: 24px;
  text-transform: capitalize;
  z-index: 0;
}

.modal .modal-dialog .modal-body .labelSeparator {
  display: flex;
  align-items: center;
}

.modal .modal-dialog .modal-body .labelSeparator span.label {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #393939;
  width: 180px;
}

.modal .modal-dialog .modal-body label.form-label {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}

.modal .modal-dialog .modal-body input.form-control {
  border: 1.5px solid #979797;
  height: 50px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000;
}

.modal .modal-dialog .modal-body .actionBtns {
}

.modal .modal-dialog .modal-body .actionBtns button.btn-theme-primary.btn {
  min-width: 148px;
  height: 50px;
  border: 1.5px solid #270089;
  color: #270089;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
}
.modal
  .modal-dialog
  .modal-body
  .actionBtns
  button.btn-theme-primary.btn[disabled] {
  color: #aaa;
  border-color: #aaa;
}

.modal .modal-dialog .modal-body .labelSeparator hr.dvdr {
  width: 100%;
  margin: 0;
}

.modal .modal-dialog .modal-body input.form-control::placeholder {
  color: #979797;
}

/* .manual-notification-accordian .accordion-button:not(.collapsed) {
  background-color: #270089;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
} */

/* .manual-notification-accordian .accordion-button {
  font-size: 20px;
  font-weight: 600;
}

.manual-notification-accordian .manual-notification-accordian-item-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.manual-notification-accordian .accordian-notification-time {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding-right: 20px;
}

.manual-notification-accordian .notifyNoRecord {
  top: 40px;
} */

/* Edit Profile Modal End */

@media (min-width: 1200px) {
  .banForm.appManagement .modal-dialog {
    --bs-modal-width: 1040px;
  }

  .banForm.modal-90.appManagement.modal .modal-dialog {
    --bs-modal-width: 90%;
  }

  .adminUserModal.modal .modal-dialog {
    max-width: 1076px;
  }
}

@media (min-width: 992px) {
  .banForm .modal-dialog {
    --bs-modal-width: 694px;
  }

  .banForm.oppHistory .modal-dialog {
    --bs-modal-width: 999px;
  }
}

@media (max-width: 1500px) {
  .borrowers .borrowerTabs ul.nav.nav-tabs li.nav-item button.nav-link {
    min-width: 100px !important;
  }

  .accountSummary .summaryBox h4 {
    white-space: unset;
  }
}
