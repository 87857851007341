/* Header */
.header {
    height: 100px;
    border-bottom: 1px solid #ccc;
    padding: 20px;
}

.header  .dropdown {display: flex;justify-content: end;}

button.dropdown-toggle .profile-flex {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 200px;
}

button.dropdown-toggle .profile-flex figure {
    margin: 0;
    width: 48px;
    height: 48px;
    margin: 0 15px 0 0;
}
.user-profile-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: brown;
    color: #ffffff;
    font-size: 20px;
    border-radius: 50%;
    font-weight: 800;
  }

  .user-profile-image-xl {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background-color: brown;
    color: #ffffff;
    font-size: 40px;
    border-radius: 50%;
    font-weight: 800;
  }
button.dropdown-toggle .profile-flex .titleBox {}

button.dropdown-toggle .profile-flex .titleBox h3 {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

button.dropdown-toggle .profile-flex .titleBox p {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.header .dropdown button.dropdown-toggle {
    display: flex;
    width: 210px;
    border: 0;
}

.header .dropdown button.dropdown-toggle::after {
    position: relative;
    top: 8px;
    width: 9px;
    height: 8px;
    border-left: 2px solid #393939;
    border-bottom: 2px solid #393939;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    transform: rotate(-45deg);
    left: 5px;
}

.header .profileDropdown {}

.header .profileDropdown .dropdown-menu.show {
    border-radius: 10px 0px 10px 10px;
    width: 176px;
}

.header .profileDropdown .dropdown-menu .dropdown-item .linkWithIcon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header .profileDropdown .dropdown-menu .dropdown-item .linkWithIcon figure {
    margin: 0 5px 0 0;
    width: 14px;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

.header .profileDropdown .dropdown-menu .dropdown-item {
    margin: 0 0 5px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #696969;
}

.header .profileDropdown .dropdown-menu .dropdown-item:focus {
    background: transparent;
}

.header .profileDropdown .dropdown-menu .dropdown-item .linkWithIcon figure svg {
    width: 100%;
    height: auto;
}